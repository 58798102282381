<template>
    <div class="shop_car_steps">
        <div class="item" :class="type === '1' ? 'on' : ''">
            <div class="icon">
                <i class="el-icon-shopping-cart-2"></i>
            </div>
            <div class="line"></div>
            <span class="text">我的购物车</span>
        </div>

        <div class="item" :class="type === '2' ? 'on' : ''">
            <div class="icon">
                <i class="el-icon-document"></i>
            </div>
            <div class="line"></div>
            <span class="text">确认订单</span>
        </div>

        <div class="item" :class="type === '3' ? 'on' : ''">
            <div class="icon">
                <i class="el-icon-wallet"></i>
            </div>
            <div class="line"></div>
            <span class="text">生成订单/支付</span>
        </div>

        <div class="item" :class="type === '4' ? 'on' : ''">
            <div class="icon">
                <i class="el-icon-document-checked"></i>
            </div>
            <div class="line"></div>
            <span class="text">交易完成</span>
        </div>
    </div>
</template>

<script>
  export default {
    name: "shopCarMenu",
    props: {
      type: {
        type: String,
        default: ''
      }
    },
    data () {
      return {}
    },
    methods: {},
    components: {}
  }
</script>
